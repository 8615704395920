import get from 'lodash/get';
import suppApi from 'utils/resources/companies';
import rlApi from 'utils/resources/requisitionLists';
import api from 'utils/resources/requestForQuotations';

/**
 * @typedef {Object} RequestManager
 * @property {function(string, function): Promise<void>} loadRequests
 * @property {function(number[], function): Promise<void>} loadRequisitionLists
 * @property {function(number[], function): Promise<void>} loadSuppliers
 * @property {function(Object, function): Promise<void>} createRequest
 * @property {function(string, Object, function): Promise<void>} updateRequest
 * @property {function(Object, string|string[], function): Promise<void>} addRLToRequest
 * @property {function(Object, string|string[], function): Promise<void>} removeRLFromRequest
 * @property {function(Object, string, function): Promise<void>} addSupplierToRequest
 * @property {function(Object, string, function): Promise<void>} removeSupplierFromRequest
 * @property {function(Object, function): Promise<void>} removeAllSuppliersFromRequest
 */

/**
 * Creates a request manager with various API-related functions.
 * @returns {RequestManager} An object containing request management functions.
 */
export const requestManager = () => {
  /**
   * Generic error handler for API calls
   * @param {string} operation - The operation that failed
   * @returns {function} Error handler function
   */
  const handleError = (operation) => (error) => {
    console.error(`Error ${operation}:`, error);
    throw error;
  };

  /**
   * Loads a single request by ID.
   * @param {string} id - The ID of the request to load.
   * @param {function(Object): void} onSuccess - Callback function with the loaded data.
   * @returns {Promise<void>}
   */
  const loadRequests = async (id, onSuccess) => {
    try {
      const { data: { data } } = await api.show(id);
      onSuccess(data);
    } catch (error) {
      handleError('loading request')(error);
    }
  };

  /**
   * Loads multiple requisition lists by their IDs.
   * @param {number[]} ids - Array of requisition list IDs to load.
   * @param {function(Object[]): void} onSuccess - Callback function with the loaded data.
   * @returns {Promise<void>}
   */
  const loadRequisitionLists = async (ids = [], onSuccess) => {
    if (!ids.length) return;
    const query = {
      filter: { id: ids },
      page: { size: 999, number: 1 },
    };
    try {
      const { data: { data } } = await rlApi.api.read({ type: 'api_resources/requisition_lists' }, { query });
      onSuccess(data);
    } catch (error) {
      handleError('loading requisition lists')(error);
    }
  };

  /**
   * Loads multiple suppliers by their IDs.
   * @param {number[]} ids - Array of supplier IDs to load.
   * @param {function(Object[]): void} onSuccess - Callback function with the loaded data.
   * @returns {Promise<void>}
   */
  const loadSuppliers = async (ids, onSuccess) => {
    if (!ids.length) {
      onSuccess([]);
      return;
    }
    const query = {
      filter: { id: ids },
      page: { size: 999, number: 1 },
    };
    try {
      const { data: { data } } = await suppApi.api.read({ type: 'api_resources/companies' }, { query });
      onSuccess(data);
    } catch (error) {
      handleError('loading suppliers')(error);
    }
  };

  /**
   * Creates a new request.
   * @param {Object} payload - The request payload.
   * @param {function(Object): void} onSuccess - Callback function with the created data.
   * @returns {Promise<void>}
   */
  const createRequest = async (payload, onSuccess) => {
    try {
      const { data: { data } } = await api.create(payload);
      onSuccess(data);
    } catch (error) {
      handleError('creating request')(error);
    }
  };

  /**
   * Updates an existing request.
   * @param {string} id - The ID of the request to update.
   * @param {Object} payload - The update payload.
   * @param {function(Object): void} onSuccess - Callback function with the updated data.
   * @returns {Promise<void>}
   */
  const updateRequest = async (id, payload, onSuccess) => {
    try {
      const { data: { data } } = await api.update(id, payload);
      onSuccess(data);
    } catch (error) {
      handleError('updating request')(error);
    }
  };

  /**
   * Adds a requisition list to a request.
   * @param {Object} request - The request object.
   * @param {string|string[]} rlId - The ID or IDs of the requisition list(s) to add.
   * @param {function(): void} onSuccess - Callback function called on success.
   * @returns {Promise<void>}
   */
  const addRLToRequest = async (request, rlId, onSuccess) => {
    const requestId = get(request, 'id');
    if (requestId) {
      try {
        await api.addRequisitionList(requestId, rlId);
        onSuccess();
      } catch (error) {
        handleError('adding requisition list to request')(error);
      }
    } else {
      onSuccess();
    }
  };

  /**
   * Removes a requisition list from a request.
   * @param {Object} request - The request object.
   * @param {string|string[]} rlId - The ID or IDs of the requisition list(s) to remove.
   * @param {function(): void} onSuccess - Callback function called on success.
   * @returns {Promise<void>}
   */
  const removeRLFromRequest = async (request, rlId, onSuccess) => {
    const requestId = get(request, 'id');
    if (requestId) {
      try {
        await api.removeRequisitionList(requestId, rlId);
        onSuccess();
      } catch (error) {
        handleError('removing requisition list from request')(error);
      }
    } else {
      onSuccess();
    }
  };

  /**
   * Adds a supplier to a request.
   * @param {Object} request - The request object.
   * @param {string} supplierId - The ID of the supplier to add.
   * @param {function(): void} onSuccess - Callback function called on success.
   * @returns {Promise<void>}
   */
  const addSupplierToRequest = async (request, supplierId, onSuccess) => {
    const requestId = get(request, 'id');
    if (requestId) {
      try {
        await api.addSupplier(requestId, supplierId);
        onSuccess();
      } catch (error) {
        handleError('adding supplier to request')(error);
      }
    } else {
      onSuccess();
    }
  };

  /**
   * Removes a supplier from a request.
   * @param {Object} request - The request object.
   * @param {string} supplierId - The ID of the supplier to remove.
   * @param {(function(data): void) | (function(): void)} onSuccess - Callback function called on success.
   * @returns {Promise<void>}
   */
  const removeSupplierFromRequest = async (request, supplierId, onSuccess) => {
    const requestId = get(request, 'id');
    if (requestId) {
      try {
        const responce = await api.removeSupplier(requestId, supplierId);
        onSuccess(responce);
      } catch (error) {
        handleError('removing supplier from request')(error);
      }
    } else {
      onSuccess();
    }
  };

  /**
   * Removes all suppliers from a request.
   * @param {Object} request - The request object.
   * @param {function(): void} onSuccess - Callback function called on success.
   * @returns {Promise<void>}
   */
  const removeAllSuppliersFromRequest = async (request, onSuccess) => {
    const requestId = get(request, 'id');
    if (requestId) {
      try {
        await api.removeAllSuppliers(requestId);
        onSuccess();
      } catch (error) {
        handleError('removing all suppliers from request')(error);
      }
    } else {
      onSuccess();
    }
  };

  return {
    loadRequests,
    loadRequisitionLists,
    loadSuppliers,
    createRequest,
    updateRequest,
    addRLToRequest,
    removeRLFromRequest,
    addSupplierToRequest,
    removeSupplierFromRequest,
    removeAllSuppliersFromRequest,
  };
};
