import { dataPayload } from "./utils";
import * as api from "../api/adapters/json_api";

const TYPE = "request_for_quotations";
const PATH = `api_resources/${TYPE}`;

/**
 * @typedef {Object} RequestForQuotations
 * @property {function(string, string): Promise<Object>} addRequisitionList
 * @property {function(string, string): Promise<Object>} removeRequisitionList
 * @property {function(string, string): Promise<Object>} addSupplier
 * @property {function(string, string): Promise<Object>} removeSupplier
 * @property {function(string): Promise<Object>} removeAllSuppliers
 * @property {function(string): Promise<Object>} show
 * @property {function(Object): Promise<Object>} create
 * @property {function(string, Object): Promise<Object>} update
 */

/**
 * @type {RequestForQuotations}
 */
const requestForQuotations = {
  /**
   * Add a requisition list to a request for quotation
   * @param {string} rfqId - The ID of the request for quotation
   * @param {string} requisitionListId - The ID of the requisition list to add
   * @returns {Promise<Object>} The API response
   */
  addRequisitionList: (rfqId, requisitionListId) => api.create(
    { type: PATH, id: rfqId, action: "add_requisition_list" },
    {},
    { requisition_list_id: requisitionListId }
  ),

  /**
   * Remove a requisition list from a request for quotation
   * @param {string} rfqId - The ID of the request for quotation
   * @param {string} requisitionListId - The ID of the requisition list to remove
   * @returns {Promise<Object>} The API response
   */
  removeRequisitionList: (rfqId, requisitionListId) => api.create(
    { type: PATH, id: rfqId, action: "remove_requisition_list" },
    {},
    { requisition_list_id: requisitionListId }
  ),

  /**
   * Add a supplier to a request for quotation
   * @param {string} rfqId - The ID of the request for quotation
   * @param {string} supplierId - The ID of the supplier to add
   * @returns {Promise<Object>} The API response
   */
  addSupplier: (rfqId, supplierId) => api.create(
    { type: PATH, id: rfqId, action: "add_supplier" },
    {},
    { supplier_id: supplierId }
  ),

  /**
   * Remove a supplier from a request for quotation
   * @param {string} rfqId - The ID of the request for quotation
   * @param {string} supplierId - The ID of the supplier to remove
   * @returns {Promise<Object>} The API response
   */
  removeSupplier: (rfqId, supplierId) => api.create(
    { type: PATH, id: rfqId, action: "remove_supplier" },
    {},
    { supplier_id: supplierId }
  ),

  /**
   * Remove all suppliers from a request for quotation
   * @param {string} rfqId - The ID of the request for quotation
   * @returns {Promise<Object>} The API response
   */
  removeAllSuppliers: (rfqId) => api.create(
    { type: PATH, id: rfqId, action: "remove_all_suppliers" },
    {},
    {}
  ),

  /**
   * Fetch a request for quotation by ID
   * @param {string} id - The ID of the request for quotation
   * @returns {Promise<Object>} The API response
   */
  show: async (id) => api.read({ type: PATH, id }, {}),

  /**
   * Create a new request for quotation
   * @param {Object} attributes - The attributes of the request for quotation
   * @param {string[]} [attributes.rlIds=[]] - The IDs of requisition lists to add
   * @returns {Promise<Object>} The API response
   */
  create: async (attributes) => {
    const { rlIds = [], ...restAttributes } = attributes;
    const payload = dataPayload(TYPE, restAttributes);

    if (rlIds.length) {
      const reqResp = await api.create({ type: PATH }, {}, payload);
      return requestForQuotations.addRequisitionList(reqResp.data.data.id, rlIds);
    }

    return api.create({ type: PATH, action: "create" }, {}, payload);
  },

  /**
   * Update an existing request for quotation
   * @param {string} id - The ID of the request for quotation to update
   * @param {Object} attributes - The attributes to update
   * @returns {Promise<Object>} The API response
   */
  update: async (id, attributes) => {
    const payload = dataPayload(TYPE, attributes, id);
    return api.update({ type: PATH, id }, {}, payload);
  }
};

export default requestForQuotations;
