import { useSelector } from 'react-redux';
import React, { useMemo, useCallback } from 'react';
import { Icon, Label, Button, ButtonGroup } from 'semantic-ui-react';

import { requestManager } from '../../requestManager';
import { REQUEST_CREATED_KEY, SUPPLIERS_SELECTED_KEY } from '../utils';
import { useLocalStorage } from '../../../../components/App/LocalStorageContext';

/**
 * SelectCompanies component for managing supplier selection
 * @component
 */
const SelectCompanies = () => {
  const [selectedSuppliers, setSelectedSuppliers] = useLocalStorage(SUPPLIERS_SELECTED_KEY, []);
  const [createdRequest] = useLocalStorage(REQUEST_CREATED_KEY, null);

  const data = useSelector((state) => state.companiesPage.data);

  const showCount = useMemo(() => data.length, [data]);
  const clearCount = useMemo(() => selectedSuppliers.length, [selectedSuppliers]);

  /**
   * Handles selecting all suppliers
   */
  const handleSelectAll = useCallback(() => {
    const ids = data.map(({ id }) => id);
    const onSuccess = () => {
      const newSelected = [...new Set([...selectedSuppliers, ...data])];
      setSelectedSuppliers(newSelected);
    };
    requestManager().addSupplierToRequest(createdRequest, ids, onSuccess);
  }, [createdRequest, data, selectedSuppliers, setSelectedSuppliers]);

  /**
   * Handles unselecting all suppliers
   */
  const handleUnselectAll = useCallback(() => {
    const onSuccess = () => setSelectedSuppliers([]);
    requestManager().removeAllSuppliersFromRequest(createdRequest, onSuccess);
  }, [createdRequest, setSelectedSuppliers]);

  /**
   * Renders a button with an icon, label, and optional count
   * @param {Object} props - Button properties
   * @param {string} props.iconName - Name of the icon to display
   * @param {string} props.label - Text label for the button
   * @param {number} props.count - Count to display (if any)
   * @param {string} props.color - Color of the count label
   * @param {Function} props.onClick - Click handler for the button
   * @returns {React.ReactElement} Button component
   */
  const renderButton = ({ iconName, label, count, color, onClick }) => (
    <Button onClick={onClick}>
      <Icon name={iconName} />
      <span>{label}</span>
      {!!count && <Label size="mini" color={color} circular>{count}</Label>}
    </Button>
  );

  return (
    <ButtonGroup basic size="tiny" className="SelectCompanies">
      {renderButton({
        iconName: 'check square outline',
        label: 'Select all',
        count: showCount,
        color: 'green',
        onClick: handleSelectAll,
      })}
      {renderButton({
        iconName: 'square outline',
        label: 'Clear all',
        count: clearCount,
        color: 'red',
        onClick: handleUnselectAll,
      })}
    </ButtonGroup>
  );
};

SelectCompanies.propTypes = {

};

export default SelectCompanies;
